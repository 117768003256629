import { useState } from "react";
import axios from "axios";
import FormPaymentConfirmation from "../molecules/payment_confirmation";
import PaymentSuccess from "../molecules/payment_success";
import PaymentFailed from "../molecules/payment_failed";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentConfirmationPage = (props) => {
    const apiTransactionURL = 'https://explorer.vexanium.com:6960/v1/history/get_transaction';
    const postincsawitApiBaseUrl = "https://api.postincsawit.store/api";
    // const postincsawitApiBaseUrl = "http://localhost:3100/api";

    const [transactionId, setTransactionId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(null);
    const [transactionDetail, setTransactionDetail] = useState(null);
    const { state } = useLocation();
    const navigate = useNavigate();

    console.log(state);

    async function confirmTransaction(data) {
        try {
            const response = await axios.post(`${postincsawitApiBaseUrl}/record_transaction`, data);
            return response.data;
            // if (response.data.message === "success") {
            //     return true;
            // } else {
            //     return false;
            // }
        } catch (error) {
            return false;
        }
    }

    async function checkTransactionRecorded(hexNumber,transactionId) {
        try {
            const response = await axios.get(`${postincsawitApiBaseUrl}/validate_transaction/${hexNumber}/${transactionId}`);
            if (response.data.message === 'valid') {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async function validateTransaction() {
        const walletAddress = '3yehdhost3hi';

        setIsLoading(true);

        const response = await axios.get(`${apiTransactionURL}/${transactionId}`);

        let paymentResult;

        if (response.data !== null) {
            console.log('trx found');
            console.log(response.data);
            const actionTrace = response.data.action_traces.filter((action) => { return action.receiver === walletAddress });
            if (actionTrace !== null && actionTrace.length !== 0) {
                console.log('action found');
                const actionTraceFiltered = actionTrace[actionTrace.length - 1];
                const transferFrom = actionTraceFiltered.act.data.from;
                const transferTo = actionTraceFiltered.act.data.to;
                const quantity = actionTraceFiltered.act.data.quantity.split(' ')[0].split('.')[0];
                const hexNumber = actionTraceFiltered.act.hex_data;
                console.log(hexNumber);
                if (quantity == state.selectGems.price) {
                    console.log('qty match');
                    if (await checkTransactionRecorded(hexNumber,transactionId)) {
                        const transactionData = {
                            hex_number:hexNumber,
                            tx_id:transactionId,
                            account_from:transferFrom,
                            account_to:transferTo,
                            amount:state.selectGems.amount,
                            price:state.selectGems.price
                        }
                        console.log(transactionData);
                        const resultRecordTransaction = await confirmTransaction(transactionData);
                        window.location.href = `${postincsawitApiBaseUrl}/get_transaction_record/${resultRecordTransaction.data.id}`;
                    } else {
                        window.location.href = `${postincsawitApiBaseUrl}/get_transaction_record/0`;
                    }

                } else {
                    window.location.href = `${postincsawitApiBaseUrl}/get_transaction_record/0`;
                }
            } else {
                window.location.href = `${postincsawitApiBaseUrl}/get_transaction_record/0`;
            }
        } else {
            console.log("Not Success");
            window.location.href = `${postincsawitApiBaseUrl}/get_transaction_record/0`;
        }

        setIsLoading(false);
        // props.handleResult(result);
    }


    return (
        <div className='mx-auto px-4 min-w-screen min-h-screen flex items-center justify-center bg-slate-200'>
            {isPaymentSuccess === null && <FormPaymentConfirmation title="Payment Confirmation" onButtonClick={validateTransaction} onInputChange={setTransactionId} isLoading={isLoading} />}
            {isPaymentSuccess === true && <PaymentSuccess title="Sukses!"
                message="Selamat, Pembayaran Anda Telah Sukses Dikonfirmasi." transactionDetail={transactionDetail} />}
            {isPaymentSuccess === false && <PaymentFailed title="Gagal!"
                message="Maaf, traksaksi tidak valid mohon check kembali transaction id lalu coba kembali"
                buttonText="Coba Lagi" buttonOnClick={() => { navigate('/purchase') }} />}
        </div>
    );
}

export default PaymentConfirmationPage;