import Card from "../../atoms/card";
import Heading1 from "../../atoms/heading1";
import Paragraph from "../../atoms/paragraph";
import TextMedium from "../../atoms/text_medium";
import TextSmall from "../../atoms/text_small";

const PaymentSuccess = (props) =>{
    const {title,message,transactionDetail} = props;
    return (
        <Card>
            <Heading1>{title}</Heading1>
            <Paragraph>{message}</Paragraph>
            <TextMedium classname="font-bold text-center">Detail Transaksi :</TextMedium>
            <TextSmall classname="text-center">Dari : {transactionDetail.from}</TextSmall>
            <TextSmall classname="text-center">Ke : {transactionDetail.to}</TextSmall>
            <TextSmall classname="text-center">Jumlah Gems Yang Dibeli : {transactionDetail.amount} Gems</TextSmall>
            <TextSmall classname="text-center">Harga : {transactionDetail.price} Sawit</TextSmall>
        </Card>
    );
}

export default PaymentSuccess;