import './App.css';
import React,{useState} from 'react'; 
import PaymentConfirmationPage from './components/pages/payment_confirmation';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PurchasePage from './components/pages/purchase';

function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<PurchasePage/>}/>
          <Route path='/purchase' element={<PurchasePage/>}/>
          <Route path='/payment_confirmation' element={<PaymentConfirmationPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
