import GemsIcon from "../../../assets/images/gems_icon.png";
import TextMedium from "../../atoms/text_medium";
import TextSmall from "../../atoms/text_small";

const GemsCard = (props) => {
    const {gemsId,gemsAmount, gemsPrice,onClick,isSelected} = props;
    return (
        <div className={`${isSelected ? 'bg-blue-500':'bg-white'} p-4 rounded-lg hover:cursor-pointer`} onClick={(event)=>{onClick(gemsId)}}>
            <div className="flex justify-center items-center content-center">
                <img src={GemsIcon} />
            </div>
            <TextMedium classname={`font-bold text-center ${isSelected ? 'text-white':''}`}>{gemsAmount} Gems</TextMedium>
            <TextSmall classname={`text-center ${isSelected ? 'text-white':''}`}>{gemsPrice} Sawit Token</TextSmall>
        </div>
    );
}

export default GemsCard;