import LoadingGif from '../../../assets/gifs/button_loading_animation.gif';
const Button = (props) => {
    const {children, classname,isLoading, onClick} = props;
    return (
        <button className={`h-10 px-6 font-semibold rounded-md ${classname} text-white flex justify-center items-center`} onClick={onClick}>
            {isLoading && <img src={LoadingGif} className='h-8'/>}
            {!isLoading && children}
        </button>
    );
}

export default Button;