import GemsCard from "../molecules/gems_card";
import ListLoading from "../../assets/gifs/list_loading_animation.gif";
const GemsList = (props) => {
    const { isLoading,gemsList,selectGems,selectedGemsId } = props;
    return (
        <>
        {
            !isLoading && <div className="justify-center items-center grid grid-cols-2 gap-4 mt-5">
                {
                    gemsList.map((item)=><GemsCard
                    gemsId={item.id} gemsAmount={item.amount} gemsPrice={item.price} key={item.id} onClick={selectGems} isSelected={selectedGemsId === item.id}/>)
                }
            </div>
        }
        {
            isLoading &&
            <div className="flex justify-center items-center">
                <img src={ListLoading} className="w-20 h-20" />
            </div>
        }
        </>
    );
}

export default GemsList;