const Card = (props) => {
    const {children} = props;

    return (
        <div className='justify-center items-center bg-white p-8 rounded-lg'>
            {children}
        </div>
    );
}

export default Card;