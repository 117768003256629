import Button from "../../atoms/button";
import Card from "../../atoms/card";
import Heading1 from "../../atoms/heading1";
import Paragraph from "../../atoms/paragraph";

const PaymentFailed = (props) =>{
    const {title,message,buttonText, buttonOnClick} = props;
    return (
        <Card>
            <Heading1>{title}</Heading1>
            <Paragraph>{message}</Paragraph>
            <Button classname='bg-blue-600 w-full' onClick={buttonOnClick}>{buttonText}</Button>
        </Card>
    );
}

export default PaymentFailed;