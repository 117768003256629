import Button from "../../atoms/button";
import Card from "../../atoms/card";
import Heading1 from "../../atoms/heading1";
import Input from "../../atoms/input";

const FormPaymentConfirmation = (props) =>{
    const {title,isLoading,onButtonClick,onInputChange} = props;
    return (
        <Card>
            <Heading1>{title}</Heading1>
            <Input label="Transaction ID" name="trx_id" placeholder="Masukkan Transaction ID" type="text" classname="mt-6 mb-3" onChange={onInputChange}/>
            <Button classname='bg-blue-600 w-full' isLoading={isLoading} onClick={onButtonClick}>Konfirmasi</Button>
        </Card>
    );
}

export default FormPaymentConfirmation;