import Heading1 from "../atoms/heading1";
import Button from "../atoms/button";
import GemsCard from "../molecules/gems_card";
import GemsList from "../organisms/gems_list";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PurchasePage = (props) => {
    const postincsawitApiBaseUrl = "https://api.postincsawit.store/api/";
    // const postincsawitApiBaseUrl = "http://localhost:3100/api/";

    const [isLoading,setIsLoading] = useState(true);
    const [gemsList, setGemsList] = useState([]);
    const [selectedGemsId, setSelectedGemsId] = useState(0);

    const navigate = useNavigate();

    async function getGemsList() {

        setIsLoading(true);

        const response = await axios.get(`${postincsawitApiBaseUrl}gems`);

        if (response.data !== null) {
            setGemsList(response.data);
        } 

        setIsLoading(false);

        // props.handleResult(result);
    }

    useEffect(()=>{
        try {
            getGemsList();
        } catch (error) {
            console.log(error);
        }

        return;
    },[]);

    console.log(selectedGemsId);

    return (
        <div className='mx-auto px-4 min-w-screen min-h-screen flex content-center items-center justify-center bg-slate-200'>
            <div className="justify-center items-center">
                <Heading1>Please Choose Amount of Gems :</Heading1>
                <GemsList isLoading={isLoading} gemsList={gemsList} selectGems={setSelectedGemsId} selectedGemsId={selectedGemsId}/>
                <Button classname="bg-blue-600 w-full mt-5" onClick={()=>{navigate('/payment_confirmation',{state:{selectGems:gemsList.filter((item)=>{return selectedGemsId === item.id})[0]}})}}>Submit</Button>
            </div>
        </div>
    );
}

export default PurchasePage;